import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authStatus } from '../../context/slice/auth.slice'
import { verifyOtpFailure, verifyOtpStart, verifyOtpSuccess } from '../../context/slice/verify-otp.slice'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Verifyotp = () => {
    const [verifyCode,setVerifyCode] = useState(null)
    const dispatch = useDispatch()
    const data = useSelector(state=>state.register)
    const [secondsRemaining, setSecondsRemaining] = useState(300); // 5 minutes = 300 seconds
    const [intervalId, setIntervalId] = useState(null);
    const {registerData} = useSelector(state=>state.register)
    const {isLoading} = useSelector(state=>state.verifyOtp)

    useEffect(() => {
      const newIntervalId = setInterval(() => {
        setSecondsRemaining((prevSeconds) => {
          const newSeconds = prevSeconds - 1;
          if (newSeconds === 0) {
            clearInterval(newIntervalId);
            dispatch(authStatus('register'))
          }
          return newSeconds;
        });
      }, 1000);
  
      setIntervalId(newIntervalId);
  
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(newIntervalId);
    }, []);
  
    const minutes = Math.floor(secondsRemaining / 60);
    const seconds = secondsRemaining % 60;

    const handleVerify = async (e) => {
      e.preventDefault();
      dispatch(verifyOtpStart());
      try {
        const response = await fetch('http://45.84.1.222/api/verify-otp/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email:registerData.email, phone_number: registerData.phone_number,code: verifyCode}),
        });
    
        if (!response.ok) {
          const error = await response.json();
          dispatch(verifyOtpFailure(error));
          toast.error("Xatolik yuz berdi qaytadan urinib ko'ring", {
            position: "top-right",
            theme: "dark",
          });
        } else {
          const data = await response.json();
          console.log(data);
          dispatch(verifyOtpSuccess(data.access));
          dispatch(authStatus('create-password'));
          toast.success(data.message, {
            position: "top-right",
            theme: "dark",
          });
        }
      } catch (err) {
        dispatch(verifyOtpFailure(err));
        toast.error('An error occurred, please try again.', {
          position: "top-right",
          theme: "dark",
        });
      }
    };
  return (
    <div className="registration-form-container">
        <h2>Verify code: {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}</h2>
        <form className="registration-form" onSubmit={handleVerify}>
          <div className="form-group">
            <label htmlFor="verify"></label>
            <input type="text" id="verify" placeholder="Enter your verify code" onChange={(e)=>setVerifyCode(e.target.value)}/>
          </div>
          <button type="submit" className="registration-button">{isLoading ? '...Loading' : 'Confirm'}</button>
        </form>
        <div className='footer__text'>Back to <span onClick={()=>dispatch(authStatus('register'))}>Sign Up</span></div>

      </div>
  )
}

export default Verifyotp