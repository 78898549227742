import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading:false,
  userData:null,
  loggedIn:false,
  error:false
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {

    // register
    loginUserStart:(state)=>{
      state.isLoading = true
      
    },
    loginUserSuccess:(state,action)=>{
      state.isLoading = false
      state.userData = action.payload.data 
      state.loggedIn = action.payload.login

    },
    loginUserFailure:(state,action)=>{
      state.isLoading = false
      state.error = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {loginUserStart,loginUserSuccess,loginUserFailure} = loginSlice.actions;

export default loginSlice.reducer;
