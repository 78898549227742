import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading:false,
  accessToken:null,
  error:false
};

export const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState,
  reducers: {

    // verify Otp
    verifyOtpStart:(state)=>{
      state.isLoading = true
      
    },
    verifyOtpSuccess:(state,action)=>{
      state.isLoading = false
      state.accessToken = action.payload
    },
    verifyOtpFailure:(state,action)=>{
      state.isLoading = false
      state.error = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {verifyOtpStart,verifyOtpSuccess,verifyOtpFailure} = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;
