import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  ColorModeContext,
  // tokens
} from "../../theme";
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../context/slice/language.slice";
import Translate from "../../utils/Translate";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import SearchIcon from "@mui/icons-material/Search";

const Topbar = () => {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const handleChange = (event) => {
    dispatch(setLanguage(event.target.value));
  };

  // useEffect(()=>{

  // },)

  return (
    <Box display="flex" justifyContent="end" p={2}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}

      {/* ICONS */}
      <Box display="flex">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="lang-label">
            <Translate dictionary={{ ru: "Язык", uz: "Til" }} />
          </InputLabel>
          <Select
            labelId="lang-label"
            id="lang"
            value={language}
            label={<Translate dictionary={{ ru: "Язык", uz: "Til" }} />}
            onChange={handleChange}
          >
            <MenuItem value={"ru"}>RU</MenuItem>
            <MenuItem value={"uz"}>UZ</MenuItem>
          </Select>
        </FormControl>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        {/* <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
