import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useRef, useState } from "react";
import { productData, uom } from "../data/mockDashboard";
import { numberStr } from "../components/numberStr";
import Translate from "../utils/Translate";

const Product = () => {
  // defaultData
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([...productData]);
  const [_uom, set_uom] = useState(uom[0].value);

  const [idx, setIdx] = useState(null);
  const [code, setCode] = useState("");
  const [_name, setName] = useState("");
  const [price, setPrice] = useState("");

  // Ref
  const codeRef = useRef();
  const nameRef = useRef();
  const uomRef = useRef();
  const priceRef = useRef();

  const saveForm = (e) => {
    e.preventDefault();
    let data1 = { name: _name, uom: _uom, code: code.padStart(3, 0), date: "" };
    data1.price = Number(price.replaceAll(" ", ""));
    if (!idx) {
      data1.quantity = 0;
      data1.id = data[data.length - 1].id + 1;
      data1.tr = data[data.length - 1].tr + 1;
      setData((prev) => [...prev, { ...data1 }]);
    } else {
      let arr = [...data];
      arr[idx] = { ...data1, quantity: arr[idx].quantity, id: idx + 1, tr: idx + 1 };
      setData(arr);
    }
    resetForm();
  };

  const resetForm = (e) => {
    setIdx(null);
    setCode("");
    setName("");
    set_uom(uom[0].value);
    setPrice("");
  };

  const handleChangeInp = (e) => {
    let text = e.target.value;
    let asii = text.length !== 0 ? text.charCodeAt(text.length - 1) : -1;
    if ((asii >= 48 && asii <= 57) || asii === -1) {
      setPrice(numberStr(e.target.value));
    }
  };

  const changeCode = (e) => {
    let text = e.target.value;
    let asii = text.length !== 0 ? text.charCodeAt(text.length - 1) : -1;
    if ((asii >= 48 && asii <= 57) || asii === -1) {
      if (e.target.value.length === 3) {
        let arr = data.filter((item) => item.code === e.target.value);
        if (arr.length === 0) {
          nameRef.current.focus();
        } else {
          setName(arr[0].name);
          set_uom(arr[0].uom);
          priceRef.current.focus();
        }
      } else {
        if (!idx) {
          setName("");
          set_uom(uom[0].value);
        }
      }
      setCode(e.target.value);
    }
  };

  const changeData = (idx) => {
    let obj = data[idx];
    setIdx(idx);
    setCode(obj.code);
    setName(obj.name);
    set_uom(obj.uom);
    setPrice(numberStr(obj.price));
    setTimeout(() => {
      codeRef.current.focus();
    }, 0);
  };

  const columns = [
    { field: "tr", headerName: "T/R", flex: 0.5 },
    {
      field: "code",
      headerName: "Code",
      flex: 0.5,
      valueGetter: ({ row: e }) => {
        return numberStr(e.code);
      },
    },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "uom",
      headerName: "UOM",
      align: "center",
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "Price",
      align: "right",
      valueGetter: ({ row: e }) => {
        return numberStr(e.price);
      },
      flex: 0.5,
    },
    // {
    //   field: "quantity",
    //   headerName: "Quantity",
    //   valueGetter: ({ row: e }) => {
    //     return numberStr(e.quantity);
    //   },
    //   align: "right",
    // },
    // {
    //   field: "total",
    //   headerName: "Total",
    //   valueGetter: ({ row: e }) => {
    //     return numberStr(e.price * e.quantity);
    //   },
    //   align: "right",
    //   flex: 1,
    // },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row: e }) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              padding: "8px 16px",
              mr: 1,
            }}
            onClick={() => changeData(e.tr - 1)}
          >
            Редактировать
          </Button>
        );
      },
      align: "center",
      cellClassName: "name-column--action",
      flex: 1.5,
    },
  ];

  return (
    <Box m="20px" pb={3}>
      <Box mb={3}>
        <Header title={{ ru: "Товар", uz: "Mahsulot" }} subtitle={{ ru: "Добавьте товар", uz: "Mahsulot qo'shish" }} />
      </Box>

      {/* Form */}
      <Box component="form" onSubmit={saveForm} autoComplete="off" py={2}>
        <Box display="flex" gap={2} alignItems="center" flexWrap="wrap" width="100%">
          <Autocomplete
            options={data}
            autoHighlight
            freeSolo
            getOptionLabel={(option) => `${option.code}`}
            renderOption={(props, option) => (
              <Box key={option.tr} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                {option.code}
              </Box>
            )}
            inputValue={code}
            renderInput={(params) => (
              <TextField
                id="code"
                {...params}
                label={<Translate dictionary={{ ru: "Код", uz: "Kod" }} />}
                required
                inputProps={{
                  ...params.inputProps,
                  minLength: 1,
                  maxLength: 3,
                }}
                inputRef={codeRef}
                onChange={changeCode}
              />
            )}
            sx={{ mb: 3, width: "10%", minWidth: "125px" }}
          />

          <TextField
            id="name"
            label={<Translate dictionary={{ ru: "Выберите товар", uz: "Mahsulotni tanlang" }} />}
            required
            variant="outlined"
            sx={{ minWidth: "300px", flexGrow: 1, mb: 3 }}
            inputRef={nameRef}
            value={_name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl sx={{ mb: 3, minWidth: "125px" }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              <Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="uom"
              label={<Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />}
              autoWidth
              inputRef={uomRef}
              value={_uom}
              onChange={(e) => set_uom(e.target.value)}
            >
              {uom.map((item, idx) => {
                return (
                  <MenuItem key={idx} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="price"
            label={<Translate dictionary={{ ru: "Цена", uz: "Narxi" }} />}
            sx={{ minWidth: "300px", flexGrow: 1, mb: 3 }}
            variant="outlined"
            required
            inputRef={priceRef}
            value={price}
            onChange={handleChangeInp}
          />
        </Box>

        <Box display="flex">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "auto",
            }}
            // onClick={saveForm}
            type="submit"
          >
            <Translate dictionary={{ ru: "Сохранять", uz: "Saqlash" }} />
          </Button>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "20px",
            }}
            onClick={resetForm}
          >
            <Translate dictionary={{ ru: "Сбросить", uz: "Tozalash" }} />
          </Button>
        </Box>
      </Box>

      {/* Table */}

      <Box
        m="20px 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name-column--action": {
            minWidth: "290px !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            overflowX: "auto !important",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(e) => setPageSize(e)}
          sx={{
            overflowY: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default Product;
