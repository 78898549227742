import { Autocomplete, Box, Button, Modal, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";
import { productData, uom } from "../data/mockDashboard";
import { Clear } from "@mui/icons-material";
import { InputText } from "../components/Input";
import { useForm } from "react-hook-form";
import { numberStr } from "../components/numberStr";
import Translate from "../utils/Translate";

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [data, setData] = useState([...productData]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    // formState: { errors },
  } = useForm();

  const handleOpen = (action) => {
    setAction(action);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const saveForm = (data1) => {
    delete data1.total;
    let [_id, _name] = data1.name.split(" - ");
    if (+_id) {
      data1.id = +_id;
      data1.name = _name;
    } else {
      data1.id = new Date().getTime();
    }
    data1.price = +data1.price;
    data1.quantity = +data1.quantity;
    if (action === "coming") {
      setData((prev) => [...prev, { ...data1 }]);
    } else if (action === "balance") {
      let arr = [...data];
      setData([
        ...arr.map((item) => {
          return item.id === data1.id ? data1 : item;
        }),
      ]);
    }
  };

  // const handleChangeSelect = (e) => {
  //   console.log(e);

  //   let [_id, _name] = e.target.innerText.split(" - ");
  //   if (+_id) {
  //     let arr = [...productData];
  //     let obj = arr.filter((item) => item.id === +_id)[0];
  //     setValue("uom", obj.uom);
  //   }
  // };

  const handleChangeInp = (e) => {
    let data = {
      ...getValues(),
    };
    data[e.target.name] = e.target.value;

    if (data.price.length && data.quantity.length) {
      setValue("total", numberStr(data.price * data.quantity));
    } else {
      setValue("total", 0);
    }
  };

  const columns = [
    { field: "tr", headerName: "T/R", flex: 0.5 },
    {
      field: "code",
      headerName: "Code",
      flex: 0.5,
      valueGetter: ({ row: e }) => {
        return numberStr(e.code);
      },
    },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "uom",
      headerName: "UOM",
      align: "center",
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "Price",
      align: "right",
      valueGetter: ({ row: e }) => {
        return numberStr(e.price);
      },
      flex: 0.5,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      valueGetter: ({ row: e }) => {
        return numberStr(e.quantity);
      },
      align: "right",
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: ({ row: e }) => {
        return numberStr(e.price * e.quantity);
      },
      align: "right",
      flex: 1,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   renderCell: ({ row: e }) => {
    //     return (
    //       <Box>
    //         <Button
    //           sx={{
    //             backgroundColor: colors.blueAccent[700],
    //             color: colors.grey[100],
    //             padding: "8px 16px",
    //             mr: 1,
    //           }}
    //           onClick={() => handleOpen("coming")}
    //         >
    //           Редактировать
    //         </Button>
    //         <Button
    //           sx={{
    //             backgroundColor: colors.redAccent[700],
    //             color: colors.grey[100],
    //             padding: "8px 16px",
    //           }}
    //           onClick={() => handleOpen("balance")}
    //         >
    //           остаток
    //         </Button>
    //       </Box>
    //     );
    //   },
    //   align: "center",
    //   cellClassName: "name-column--action",
    //   flex: 1.5,
    // },
  ];

  return (
    <Box m="20px" pb={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={{ ru: "Товары", uz: "Mahsulotlar" }}
          subtitle={{ ru: "Список товаров", uz: "Mahsulotlar ro'yxati" }}
        />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              mr: 1,
            }}
            onClick={() => handleOpen("coming")}
          >
            Приход
          </Button>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => handleOpen("balance")}
          >
            остаток
          </Button>
        </Box> */}
      </Box>

      <Box
        m="20px 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name-column--action": {
            minWidth: "290px !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            overflowX: "auto !important",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(e) => setPageSize(e)}
          sx={{
            overflowY: "auto",
          }}
        />
      </Box>

      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              position: "relative",
              p: 4,
              pt: 6,
            }}
          >
            <Button
              sx={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
                p: 1,
                width: "36px",
                minWidth: "0",
                borderRadius: "50%",
                position: "absolute",
                top: 8,
                right: 8,
              }}
              onClick={handleClose}
            >
              <Clear sx={{ width: "20px", height: "20px" }} />
            </Button>

            <Header title="Приход" subtitle="Добавить товар" />

            <Box
              component="form"
              autoComplete="off"
              py={2}
              // onSubmit={handleSubmit(saveForm)}
            >
              <Box display="flex" gap={2} alignItems="center">
                <Autocomplete
                  options={data}
                  autoHighlight
                  freeSolo
                  getOptionLabel={(option) => option.code + ` - ` + option.name}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.code} - {option.name}
                    </Box>
                  )}
                  // onChange={handleChangeSelect}
                  renderInput={(params) => (
                    <TextField
                      {...register("name", { required: true })}
                      {...params}
                      label={<Translate dictionary={{ ru: "Выберите товар", uz: "Mahsulotni tanlang" }} />}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      // placeholder={<Translate dictionary={{ ru: "Выберите товар", uz: "Mahsulotni tanlang" }} />}
                    />
                  )}
                  sx={{ mb: 3, flex: 4.5 }}
                  // onChange={(_, newValue) => {
                  //   console.log(newValue);
                  // }}
                />
                <Autocomplete
                  options={uom}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register("uom", { required: true })}
                      label={<Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />}
                      inputProps={{
                        ...params.inputProps,
                      }}
                      // placeholder={<Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />}
                    />
                  )}
                  sx={{ mb: 3, flex: 1.5 }}
                  // onChange={(_, newValue) => {
                  //   console.log(newValue);
                  // }}
                />
              </Box>
              <Box display="flex" gap={2} alignItems="center">
                <InputText
                  label={{ ru: "Цена *", uz: "Narxi *" }}
                  placeholder="1 000 000"
                  sx={{ flex: 2 }}
                  reg={{ ...register("price", { required: true }) }}
                  change={handleChangeInp}
                />
                <InputText
                  label={{ ru: "Количество *", uz: "Miqdori *" }}
                  placeholder="1 000"
                  type="number"
                  sx={{ flex: 1 }}
                  reg={{ ...register("quantity", { required: true }) }}
                  change={handleChangeInp}
                />
              </Box>

              <InputText
                label={{ ru: "Итоговая цена", uz: "Umumiy narx" }}
                readOnly={true}
                reg={{ ...register("total", { required: true }) }}
                defaultValue={0}
              />
              <Box display="flex">
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    marginLeft: "auto",
                  }}
                  onClick={handleSubmit(saveForm)}
                  type="submit"
                >
                  <Translate dictionary={{ ru: "Сохранять", uz: "Saqlash" }} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Products;
