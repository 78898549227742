import { TextField } from "@mui/material";
import Translate from "./../utils/Translate";
import React, { useEffect } from "react";

export const InputText = React.forwardRef(
  (
    {
      label = "",
      variant = "outlined",
      type = "text",
      placeholder = "",
      readOnly = false,
      sx = {},
      reg = {},
      change,
      defaultValue = "",
      InputLabelProps = {},
    },
    ref
  ) => {
    useEffect(() => {}, [label, variant, type, placeholder, readOnly, sx, reg, change, defaultValue, InputLabelProps]);
    return (
      <TextField
        label={<Translate dictionary={label} />}
        variant={variant}
        sx={{ width: "100%", mb: 3, ...sx }}
        type={type}
        placeholder={placeholder}
        InputProps={{
          readOnly,
          onInput: change,
        }}
        inputRef={ref}
        {...reg}
        defaultValue={defaultValue}
        InputLabelProps={InputLabelProps}
      />
    );
  }
);
