import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useRef, useState } from "react";
import { productData, uom } from "../data/mockDashboard";
import { numberStr } from "../components/numberStr";
import Translate from "../utils/Translate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/uz-latn";
import { useSelector } from "react-redux";

const Balance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useSelector((state) => state.language);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([...productData]);
  const [_uom, set_uom] = useState(uom[0].value);

  const codeRef = useRef();
  const nameRef = useRef();
  const uomRef = useRef();
  const priceRef = useRef();
  const quantityRef = useRef();

  const [idx, setIdx] = useState(null);
  const [code, setCode] = useState("");
  const [_name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [date, setDate] = useState(new Date());

  const getDate = (time) => {
    let timeX = new Date(time);
    let _date =
      timeX.getDate().toString().padStart(2, 0) +
      "." +
      (timeX.getMonth() + 1).toString().padStart(2, 0) +
      "." +
      timeX.getFullYear();
    return _date;
  };

  const saveForm = (e) => {
    e.preventDefault();
    let data1 = { name: _name, uom: _uom, code, date: getDate(date) };
    data1.price = Number(price.replaceAll(" ", ""));
    data1.quantity = Number(quantity.replaceAll(" ", ""));
    let arr = [...data];
    arr[idx - 1] = { ...data1, id: idx, tr: idx };
    setData(arr);
    resetForm();
  };

  const resetForm = (e) => {
    setIdx(null);
    setCode("");
    setName("");
    set_uom(uom[0].value);
    setPrice("");
    setQuantity("");
  };

  const handleChangeInp = (e) => {
    let text = e.target.value;
    let asii = text.length !== 0 ? text.charCodeAt(text.length - 1) : -1;
    if ((asii >= 48 && asii <= 57) || asii === -1) {
      setQuantity(numberStr(e.target.value));
    }
  };

  const changeCode = (e) => {
    let text = e.target.value;
    let asii = text.length !== 0 ? text.charCodeAt(text.length - 1) : -1;
    if ((asii >= 48 && asii <= 57) || asii === -1) {
      if (e.target.value.length === 3) {
        let arr = data.filter((item) => item.code === e.target.value);
        if (arr.length !== 0) {
          setName(arr[0].name);
          set_uom(arr[0].uom);
          setPrice(numberStr(arr[0].price));
          setIdx(arr[0].id);
          quantityRef.current.focus();
        }
      } else {
        if (!idx) {
          setName("");
          setPrice("");
          set_uom(uom[0].value);
        }
      }
      setCode(e.target.value);
    }
  };

  const changeData = (idx) => {
    let obj = data[idx];
    setIdx(obj.id);
    setCode(obj.code);
    setName(obj.name);
    set_uom(obj.uom);
    setPrice(numberStr(obj.price));
    setQuantity(numberStr(obj.quantity));
  };

  const columns = [
    { field: "tr", headerName: "T/R", flex: 0.5 },
    {
      field: "code",
      headerName: "Code",
      flex: 0.5,
      valueGetter: ({ row: e }) => {
        return numberStr(e.code);
      },
    },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "uom",
      headerName: "UOM",
      align: "center",
      flex: 0.5,
    },
    {
      field: "date",
      headerName: "Date",
      align: "right",
      valueGetter: ({ row: e }) => {
        return e.date;
      },
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "Price",
      align: "right",
      valueGetter: ({ row: e }) => {
        return numberStr(e.price);
      },
      flex: 0.5,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      valueGetter: ({ row: e }) => {
        return numberStr(e.quantity);
      },
      align: "right",
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row: e }) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              padding: "8px 16px",
              mr: 1,
            }}
            onClick={() => changeData(e.tr - 1)}
          >
            Редактировать
          </Button>
        );
      },
      align: "center",
      cellClassName: "name-column--action",
      flex: 1.5,
    },
  ];

  return (
    <Box m="20px" pb={3}>
      <Box mb={3}>
        <Header
          title={{ ru: "Остаток", uz: "Chiqim" }}
          subtitle={{ ru: "Выходное количество товара", uz: "Mahsulot miqdorini chiqarish" }}
        />
      </Box>

      {/* Form */}
      <Box component="form" autoComplete="off" onSubmit={saveForm} py={2}>
        <Box display="flex" gap={2} alignItems="center" flexWrap="wrap" width="100%">
          <Autocomplete
            options={data}
            autoHighlight
            getOptionLabel={(option) => `${option.code}`}
            renderOption={(props, option) => (
              <Box key={option.tr} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                {option.code}
              </Box>
            )}
            inputValue={code}
            renderInput={(params) => (
              <TextField
                id="code"
                {...params}
                label={<Translate dictionary={{ ru: "Код", uz: "Kod" }} />}
                required
                inputProps={{
                  ...params.inputProps,
                  minLength: 1,
                  maxLength: 3,
                }}
                inputRef={codeRef}
                onChange={changeCode}
              />
            )}
            sx={{ mb: 3, width: "10%", minWidth: "125px" }}
          />

          <TextField
            id="name"
            label={<Translate dictionary={{ ru: "Выберите товар", uz: "Mahsulotni tanlang" }} />}
            variant="outlined"
            sx={{ minWidth: "300px", flexGrow: 1, mb: 3 }}
            inputRef={nameRef}
            value={_name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              readOnly: true,
            }}
          />
          <FormControl sx={{ mb: 3, minWidth: "125px" }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              <Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="uom"
              label={<Translate dictionary={{ ru: "Единица измерения", uz: "O'lchov birlik" }} />}
              autoWidth
              inputRef={uomRef}
              value={_uom}
              onChange={(e) => set_uom(e.target.value)}
              readOnly
            >
              {uom.map((item, idx) => {
                return (
                  <MenuItem key={idx} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="price"
            label={<Translate dictionary={{ ru: "Цена", uz: "Narxi" }} />}
            sx={{ minWidth: "300px", flexGrow: 1, mb: 3 }}
            variant="outlined"
            inputRef={priceRef}
            value={price}
            inputProps={{
              readOnly: true,
            }}
          />
          <TextField
            id="quantity"
            required
            label={<Translate dictionary={{ ru: "Количество", uz: "Miqdori" }} />}
            sx={{ minWidth: "200px", mb: 3 }}
            variant="outlined"
            inputRef={quantityRef}
            value={quantity}
            onChange={handleChangeInp}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language === "ru" ? "ru" : "uz-latn"}>
            <DatePicker
              disableFuture
              label={<Translate dictionary={{ ru: "Дата", uz: "Sana" }} />}
              views={["year", "month", "day"]}
              value={dayjs(date)}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => <TextField required id="date" {...params} sx={{ mb: 3, minWidth: "200px" }} />}
            />
          </LocalizationProvider>
        </Box>

        <Box display="flex">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "auto",
            }}
            type="submit"
          >
            <Translate dictionary={{ ru: "Сохранять", uz: "Saqlash" }} />
          </Button>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "20px",
            }}
            onClick={resetForm}
          >
            <Translate dictionary={{ ru: "Сбросить", uz: "Tozalash" }} />
          </Button>
        </Box>
      </Box>

      {/* Table */}

      <Box
        m="20px 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name-column--action": {
            minWidth: "290px !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            overflowX: "auto !important",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(e) => setPageSize(e)}
          sx={{
            overflowY: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default Balance;
