import React, { useState } from 'react';
import '../style/RegistrationPage.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import Login from '../scenes/auth/login';
import Register from '../scenes/auth/register';
import Verifyotp from '../scenes/auth/verifyotp';
import CreatePass from '../scenes/auth/createPass';



const Auth = () => {
  const {status} = useSelector(state=>state.auth)
  console.log(status);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement login, registration, or password reset logic here
  };

  const renderForm = () => {
    switch (status) {
      case 'login':
        return (
          <Login/>
        );
      case 'register':
        return (
          <Register/>
        );
      case 'forgotPassword':
        return (
          <form onSubmit={handleSubmit}>
            <h1>Forgot Password</h1>
            <input type="email" placeholder="Email" required />
            <button type="submit">Reset Password</button>
          </form>
        );
      case 'verify':
          return (
            <Verifyotp/>
          );
      case 'create-password':
          return (
            <CreatePass/>
          );         
      default:
        return null;
    }
  };

  return (
    <div className="registration-page">
      <ToastContainer/>
        {renderForm()}
    </div>
  );
};

export default Auth;