import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useRef, useState } from "react";
import { productData, sale, uom } from "../data/mockDashboard";
import { numberStr } from "../components/numberStr";
import Translate from "../utils/Translate";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const Sale = () => {
  // defaultData
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([...sale]);
  const { language } = useSelector((state) => state.language);

  const [idx, setIdx] = useState(null);
  const [price, setPrice] = useState("");
  const [date, setDate] = useState(null);

  // Ref
  const priceRef = useRef();

  const getDate = (time) => {
    let timeX = new Date(time);
    let _date =
      timeX.getDate().toString().padStart(2, 0) +
      "." +
      (timeX.getMonth() + 1).toString().padStart(2, 0) +
      "." +
      timeX.getFullYear();
    return _date;
  };

  const saveForm = (e) => {
    e.preventDefault();
    let data1 = { date: getDate(date) };
    data1.price = Number(price.replaceAll(" ", ""));
    if (!idx) {
      data1.id = data[data.length - 1].id + 1;
      data1.tr = data[data.length - 1].tr + 1;
      setData((prev) => [...prev, { ...data1 }]);
    } else {
      let arr = [...data];
      arr[idx] = { ...data1, id: idx + 1, tr: idx + 1 };
      setData(arr);
    }
    resetForm();
  };

  const resetForm = (e) => {
    setIdx(null);
    setPrice("");
    setDate(null);
  };

  const handleChangeInp = (e) => {
    let text = e.target.value;
    let asii = text.length !== 0 ? text.charCodeAt(text.length - 1) : -1;
    if ((asii >= 48 && asii <= 57) || asii === -1) {
      setPrice(numberStr(e.target.value));
    }
  };

  const changeData = (idx) => {
    let obj = data[idx];
    setIdx(idx);
    setPrice(numberStr(obj.price));
    setDate(new Date(obj.date));
    setTimeout(() => {
      priceRef.current.focus();
    }, 0);
  };

  const columns = [
    { field: "tr", headerName: "T/R", flex: 0.5 },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: ({ row: e }) => {
        return numberStr(e.price);
      },
    },
    {
      field: "date",
      headerName: "Date",
      cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row: e }) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              padding: "8px 16px",
              mr: 1,
            }}
            onClick={() => changeData(e.tr - 1)}
          >
            Редактировать
          </Button>
        );
      },
      align: "center",
      cellClassName: "name-column--action",
      flex: 1.5,
    },
  ];

  return (
    <Box m="20px" pb={3}>
      <Box mb={3}>
        <Header title={{ ru: "Кассовый аппарат", uz: "Kassa apparati" }} subtitle={{ ru: "", uz: "" }} />
      </Box>

      {/* Form */}
      <Box component="form" onSubmit={saveForm} autoComplete="off" py={2}>
        <Box display="flex" gap={2} alignItems="stretch" flexWrap="wrap" width="100%">
          <TextField
            id="price"
            label={<Translate dictionary={{ ru: "Цена", uz: "Narxi" }} />}
            sx={{ width: "300px" }}
            variant="outlined"
            required
            inputRef={priceRef}
            value={price}
            onChange={handleChangeInp}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language === "ru" ? "ru" : "uz-latn"}>
            <DatePicker
              disableFuture
              label={<Translate dictionary={{ ru: "Дата", uz: "Sana" }} />}
              views={["year", "month", "day"]}
              value={date ? dayjs(date) : date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => <TextField required id="date" {...params} sx={{ minWidth: "200px" }} />}
            />
          </LocalizationProvider>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "auto",
            }}
            // onClick={saveForm}
            type="submit"
          >
            <Translate dictionary={{ ru: "Сохранять", uz: "Saqlash" }} />
          </Button>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={resetForm}
          >
            <Translate dictionary={{ ru: "Сбросить", uz: "Tozalash" }} />
          </Button>
        </Box>

        {/* <Box display="flex">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "auto",
            }}
            // onClick={saveForm}
            type="submit"
          >
            <Translate dictionary={{ ru: "Сохранять", uz: "Saqlash" }} />
          </Button>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "20px",
            }}
            onClick={resetForm}
          >
            <Translate dictionary={{ ru: "Сбросить", uz: "Tozalash" }} />
          </Button>
        </Box> */}
      </Box>

      {/* Table */}

      <Box
        m="20px 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name-column--action": {
            minWidth: "290px !important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            overflowX: "auto !important",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(e) => setPageSize(e)}
          sx={{
            overflowY: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default Sale;
