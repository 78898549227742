import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authStatus } from '../../context/slice/auth.slice'


import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IMaskInput } from 'react-imask'

import { loginUserFailure, loginUserStart, loginUserSuccess } from '../../context/slice/login.slice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch()
    const [email,setEmail] = useState(null)
    const [phone,setPhone] = useState(null)
    const [password,setPassword] = useState(null)
    const [tab,setTab] = useState('email')
    const ref = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate()
    const {isLoading} = useSelector(state=>state.login)

    const changeTab = (data) =>{
      setTab(data)
      setPhone(null)
      setEmail(null)
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(loginUserStart());
  
    try {
      const response = await fetch('http://45.84.1.222/api/login/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:email, phone_number: phone, password:password }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        dispatch(loginUserFailure(error));
        toast.error("Xatolik yuz berdi qaytadan urinib ko'ring", {
          position: "top-right",
          theme: "dark",
        });
      } else {
        const data = await response.json();
        // Access tokenni localStorage-da saqlash
        localStorage.setItem('accessToken', data.access);

        dispatch(loginUserSuccess({login:true,data:data.user}));
        toast.success(data.message, {
          position: "top-right",
          theme: "dark",
        });
        navigate('/')
      }
    } catch (err) {
      dispatch(loginUserFailure(err));
      toast.error('An error occurred, please try again.', {
        position: "top-right",
        theme: "dark",
      });
    }
  };


  return (
    <div className="registration-form-container">
        <h2>Login</h2>
        <div className='tabs'>
            <div className={`tab__email ${tab === 'email' ? 'active' : ''}`} onClick={()=>changeTab('email')}><MdEmail/></div>
            <div className={`tab__phone ${tab === 'phone' ? 'active' : ''}`} onClick={()=>changeTab('phone')}><FaPhone/></div>
        </div>
        <form className="registration-form" onSubmit={handleLogin}>
        {tab === 'email' ? 
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}/>
          </div>
          :
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <IMaskInput
                mask={'+{998}(00) 000-00-00'}
                radix="."
                value={phone}
                unmask={true} // true|false|'typed'
                ref={ref}
                inputRef={inputRef}  // access to nested input
                onAccept={(value, mask) => setPhone(value)}
                placeholder='Enter number here'/>
          </div>
          }
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" placeholder="Enter password" onChange={(e)=>setPassword(e.target.value)}/>
          </div>
          <button type="submit" className="registration-button">{isLoading ? "...loading" : "Login"}</button>
        </form>
        <div className='footer__text'> Don’t have an account ?  <span onClick={()=>dispatch(authStatus('register'))}> Sign up now</span></div>
      </div>
  )
}

export default Login