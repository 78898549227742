import { Box, Button, TextField, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { departmentData } from "../data/mockDashboard";
import { useState } from "react";
import Translate from "../utils/Translate";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { numberStr } from "../components/numberStr";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  const [pageSize, setPageSize] = useState(10);

  const { language } = useSelector((state) => state.language);

  const columns = [
    {
      field: "department_name",
      renderHeader: () => <Translate dictionary={{ ru: "Наименование отдела", uz: "Bo'lim nomi" }} />,
      renderCell: ({ value }) => {
        return <Translate dictionary={value} />;
      },
    },
    {
      field: "balance",
      renderHeader: () => <Translate dictionary={{ ru: "Остаток на начало", uz: "Boshlang'ich balans" }} />,
      // flex: 0.5,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
    },
    {
      field: "receipt",
      renderHeader: () => <Translate dictionary={{ ru: "Приход", uz: "Kirim" }} />,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
      // flex: 0.5,
    },
    {
      field: "shipment",
      renderHeader: () => <Translate dictionary={{ ru: "Фактический расход", uz: "Chiqim" }} />,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
      // flex: 0.5,
    },
    {
      field: "actual_balance",
      renderHeader: () => <Translate dictionary={{ ru: "Фактический остаток", uz: "Qoldiq" }} />,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
      // flex: 0.5,
    },
    {
      field: "sales",
      renderHeader: () => <Translate dictionary={{ ru: "Объем реализации (выручка)", uz: "Sotish hajmi (daromad)" }} />,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
      // flex: 0.5,
    },
    {
      field: "actual_markup",
      renderHeader: () => <Translate dictionary={{ ru: "Фактическая наценка", uz: "Haqiqiy foyda" }} />,
      renderCell: ({ value }) => {
        return numberStr(value);
      },
      // flex: 0.5,
    },
    {
      field: "percent",
      renderHeader: () => <Translate dictionary={{ ru: "Факт нацен %", uz: "Haqiqiy foyda %" }} />,
      // flex: 0.5,
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box mb={3}>
        <Header
          title={{ uz: "Bosh sahifa", ru: "Главная" }}
          subtitle={{ uz: "Boshqaruv panelingizga xush kelibsiz", ru: "Добро пожаловать в вашу панель управления" }}
        />

        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={() => setDay(0)}
          >
            <Translate dictionary={{ ru: "Сегодня", uz: "Bugun" }} />
          </Button>
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => setDay(1)}
            >
              <Translate dictionary={{ ru: "Текущая дата", uz: "Joriy sana" }} />
            </Button>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: 2,
              }}
              onClick={() => setDay(2)}
            >
              <Translate dictionary={{ ru: "Еженедельно", uz: "Haftalik" }} />
            </Button>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginLeft: 2,
              }}
              onClick={() => setDay(3)}
            >
              <Translate dictionary={{ ru: "Ежемесячно", uz: "Oylik" }} />
            </Button>
          </Box>
        </Box> */}

        {/* <ConfigProvider
          theme={
            {
            token: {
              colorPrimary: "white",
              colorBgBase: colors.primary[500],
              colorTextBase: "white",
              
              colorTextLightSolid: colors.primary[500],
            },
          }
        }
        >
          <Space size={12}>
            <RangePicker size="large" className="range-picker" />
          </Space>
        </ConfigProvider> */}

        <Box sx={{ display: "flex", alignItems: "stretch", justifyContent: "flex-start" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language === "ru" ? "ru" : "uz-latn"}>
            <DatePicker
              disableFuture
              maxDate={endDay}
              label="Start date"
              value={startDay}
              onChange={(newValue) => {
                setStartDay(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ marginRight: 2 }} />}
            />
            <DatePicker
              disableFuture
              minDate={startDay}
              label="End date"
              value={endDay}
              onChange={(newValue) => {
                setEndDay(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: 2,
            }}
          >
            <Translate dictionary={{ ru: "Поиск", uz: "Qidirish" }} />
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box pb={1}>
        <Box
          m="20px 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .name-column--action": {
              minWidth: "290px !important",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
              overflowX: "auto !important",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={departmentData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(e) => setPageSize(e)}
            sx={{
              overflowY: "auto",
            }}
          />
        </Box>
        {/* {dashboardData2.map((item, idx) => {
          return (
            <Box display="grid" gridTemplateColumns="repeat(24, 1fr)" gap="30px" alignItems="center" mb="30px">
              {idx === dashboardData2.length - 1 && (
                <Box gridColumn="span 24">
                  <hr />
                </Box>
              )}
              <Box
                key={idx}
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius={1}
                height="50%"
              >
                <Typography variant="h6" fontWeight="bold" sx={{ color: colors.grey[100], textAlign: "center", mx: 1 }}>
                  <Translate dictionary={item.title} />
                </Typography>
              </Box>
              <Box
                display="grid"
                gridColumn="span 21"
                gridTemplateColumns="repeat(21, 1fr)"
                gridAutoRows="140px"
                gap="20px"
              >
                {dashboardData1.map((item, idx) => {
                  return (
                    item.day === day && (
                      <Box
                        key={idx}
                        gridColumn="span 3"
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius={1}
                      >
                        <DashBox title={item.title} price={item.price} />
                      </Box>
                    )
                  );
                })}
              </Box>
            </Box>
          );
        })} */}
      </Box>
    </Box>
  );
};

export default Dashboard;
