import React, { useState } from 'react'
import { createPassFailure, createPassStart, createPassSuccess } from '../../context/slice/createpass.slice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authStatus } from '../../context/slice/auth.slice';
import { useNavigate } from 'react-router-dom';
import { loginUserSuccess } from '../../context/slice/login.slice';

const CreatePass = () => {
    const [password,setPassword] =useState(null)
    const [confirm,setConfirm] =useState(null)
    const {accessToken} = useSelector(state=>state.verifyOtp)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) =>{
      e.preventDefault();
      dispatch(createPassStart());
      try {
        const response = await fetch('http://45.84.1.222/api/create-password/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` // Token qo'shildi
          },
          body: JSON.stringify({ password:password, confirm_password:confirm}),
        });

        if (!response.ok) {
            const error = await response.json();
            dispatch(createPassFailure(error));
            toast.error("Xatolik yuz berdi qaytadan urinib ko'ring", {
              position: "top-right",
              theme: "dark",
            });
          } else {
            const data = await response.json();
            dispatch(createPassSuccess());
            dispatch(authStatus('login'));
            toast.success(data.message, {
              position: "top-right",
              theme: "dark",
            });
          }
      } catch (error) {
        dispatch(createPassFailure(error));
        toast.error('An error occurred, please try again.', {
          position: "top-right",
          theme: "dark",
        });
      }
    }
  return (
    <div className="registration-form-container">
        <h2>Create New Password</h2>

        <form className="registration-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password"></label>
            <input type="text" id="password" placeholder="New password" onChange={(e)=>setPassword(e.target.value)}/>
          </div>
          <div className="form-group">
            <label htmlFor="confirm"></label>
            <input type="text" id="confirm" placeholder="Confirm password" onChange={(e)=>setConfirm(e.target.value)}/>
          </div>
          <button type="submit" className="registration-button">Create password</button>
        </form>
        <div className='footer__text'>Back to <span onClick={()=>dispatch(authStatus('register'))}>Sign Up</span></div>

      </div>
  )
}

export default CreatePass