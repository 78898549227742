import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading:false,
  error:false
};

export const createPass = createSlice({
  name: "createPassword",
  initialState,
  reducers: {

    // verify Otp
    createPassStart:(state)=>{
      state.isLoading = true
      
    },
    createPassSuccess:(state,action)=>{
      state.isLoading = false

    },
    createPassFailure:(state,action)=>{
      state.isLoading = false
      state.error = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {createPassStart,createPassSuccess,createPassFailure} = createPass.actions;

export default createPass.reducer;
