import React, { useRef, useState } from 'react'

import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";


import { IMaskInput } from 'react-imask'

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { signUserFailure, signUserStart, signUserSuccess } from '../../context/slice/register.slice';
import { authStatus } from '../../context/slice/auth.slice';
const Register = () => { 
    const [email,setEmail] = useState(null)
    const [phone,setPhone] = useState(null)
    const [tab,setTab] = useState('email')
    const ref = useRef(null);
    const inputRef = useRef(null);
    const {isLoading} = useSelector(state=>state.register)
    const dispatch = useDispatch()

    const changeTab = (data) =>{
        setTab(data)
        setPhone(null)
        setEmail(null)
    }

    const handleRegister = async (e) => {
      e.preventDefault();
      dispatch(signUserStart());
    
      try {
        const response = await fetch('http://45.84.1.222/api/register/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, phone_number: phone }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          dispatch(signUserFailure(error));
          toast.error("Xatolik yuz berdi qaytadan urinib ko'ring", {
            position: "top-right",
            theme: "dark",
          });
        } else {
          const data = await response.json();
          console.log(data);
          dispatch(signUserSuccess({ email, phone_number: phone }));
          dispatch(authStatus('verify'));
          toast.success(data.message, {
            position: "top-right",
            theme: "dark",
          });
        }
      } catch (err) {
        dispatch(signUserFailure(err));
        toast.error('An error occurred, please try again.', {
          position: "top-right",
          theme: "dark",
        });
      }
    };
  
    return(
    <div className="registration-form-container">
        <h2>Register</h2>
        <div className='tabs'>
            <div className={`tab__email ${tab === 'email' ? 'active' : ''}`} onClick={()=>changeTab('email')}><MdEmail/></div>
            <div className={`tab__phone ${tab === 'phone' ? 'active' : ''}`} onClick={()=>changeTab('phone')}><FaPhone/></div>
        </div>
        <form className="registration-form" onSubmit={handleRegister}>
            {tab === 'email' ? 
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}/>
          </div>
          :
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <IMaskInput
                mask={'+{998}(00) 000-00-00'}
                radix="."
                value={phone}
                unmask={true} // true|false|'typed'
                ref={ref}
                inputRef={inputRef}  // access to nested input
                onAccept={(value, mask) => setPhone(value)}
                placeholder='Enter number here'/>
          </div>
          }
          
          <button type="submit" className="registration-button" disabled={isLoading}>{isLoading ? '...Loading' : 'Register'}</button>
        </form>
          <div className='footer__text'>Already have an account ? <span onClick={()=>dispatch(authStatus('login'))}>Sign in now</span></div>
      </div>
  )
    }
export default Register
