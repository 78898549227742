import { createSlice } from "@reduxjs/toolkit";


const firstStatus = localStorage.getItem("status")
const initialState = {
  status: firstStatus === null ? 'register' : firstStatus
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // register
    authStatus:(state,action)=>{
        localStorage.setItem('status',action.payload)
        state.status = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {authStatus} = authSlice.actions;

export default authSlice.reducer;
