import { useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Products from "./pages/Products";
import Product from "./pages/Product";
import Receipt from "./pages/Receipt";
import Balance from "./pages/Balance";
import Sale from "./pages/Sale";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loginUserSuccess } from "./context/slice/login.slice";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {loggedIn,userData} = useSelector(state=>state.login)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // localStorage-dan accessToken-ni olish
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          // Agar accessToken mavjud bo'lsa, foydalanuvchi ma'lumotlarini olish
          const response = await fetch('http://45.84.1.222/api/profile/', {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          });
          const user = await response.json();
          dispatch(loginUserSuccess({ login: response.ok, data: user }));

          if(!response.ok){
            navigate('/auth')
          }
          // Foydalanuvchi ma'lumotlarini Redux store-ga yuklash
        }
        else{
          navigate('/auth')
        }
      } catch (error) {
        navigate('/auth')
      }
    };

    fetchUserData();
  }, [dispatch]);

  
  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
      
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/product" element={<Product />} />
              <Route path="/products/change" element={<Receipt />} />
              <Route path="/products/balance" element={<Balance />} />
              <Route path="/products/sale" element={<Sale />} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
