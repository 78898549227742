export const numberStr = (n) => {
  let num = n.toString().replaceAll(" ", "");
  let newNum = [];

  while (num >= 1000) {
    newNum.unshift((num % 1000).toString().padStart(3, 0));
    num = (num - (num % 1000)) / 1000;
  }
  if (newNum.length) {
    return `${num} ${newNum.join(" ")}`;
  } else {
    return num;
  }
};
