import { configureStore } from "@reduxjs/toolkit";

// slices
import language from "./slice/language.slice";
import register from "./slice/register.slice";
import auth from "./slice/auth.slice";
import verifyOtp from "./slice/verify-otp.slice";
import createPass from "./slice/createpass.slice";
import login from "./slice/login.slice";


export const store = configureStore({
  reducer: {
    language,
    register,
    auth,
    verifyOtp,
    createPass,
    login
  },
  devTools: process.env.NODE_ENV !== "production",
});
