import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading:false,
  registerData:{},
  error:false
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {

    // register
    signUserStart:(state)=>{
      state.isLoading = true
      
    },
    signUserSuccess:(state,action)=>{
      state.isLoading = false
      state.registerData = action.payload

    },
    signUserFailure:(state,action)=>{
      state.isLoading = false
      state.error = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {signUserStart,signUserSuccess,signUserFailure} = registerSlice.actions;

export default registerSlice.reducer;
