import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Translate from "../utils/Translate";

export const dashboardData = [
  {
    title: "12,361",
    subtitle: "Emails Sent",
    progress: "0.75",
    increase: "+14%",
    icon: EmailIcon,
    day: 0,
  },
  {
    title: "431,225",
    subtitle: "Sales Obtained",
    progress: "0.50",
    increase: "+21%",
    icon: PointOfSaleIcon,
    day: 0,
  },
  {
    title: "32,441",
    subtitle: "New Clients",
    progress: "0.30",
    increase: "+5%",
    icon: PersonAddIcon,
    day: 0,
  },
  {
    title: "1,325,134",
    subtitle: "Traffic Received",
    progress: "0.80",
    increase: "+43%",
    icon: TrafficIcon,
    day: 0,
  },
  {
    title: "10,000",
    subtitle: "Emails Sent",
    progress: "0.65",
    increase: "+10%",
    icon: EmailIcon,
    day: 1,
  },
  {
    title: "400,225",
    subtitle: "Sales Obtained",
    progress: "0.40",
    increase: "+18%",
    icon: PointOfSaleIcon,
    day: 1,
  },
  {
    title: "30,441",
    subtitle: "New Clients",
    progress: "0.50",
    increase: "+25%",
    icon: PersonAddIcon,
    day: 1,
  },
  {
    title: "1,000,134",
    subtitle: "Traffic Received",
    progress: "0.70",
    increase: "+35%",
    icon: TrafficIcon,
    day: 1,
  },
  {
    title: "14,361",
    subtitle: "Emails Sent",
    progress: "0.65",
    increase: "+9%",
    icon: EmailIcon,
    day: 2,
  },
  {
    title: "500,225",
    subtitle: "Sales Obtained",
    progress: "0.60",
    increase: "+25%",
    icon: PointOfSaleIcon,
    day: 2,
  },
  {
    title: "40,441",
    subtitle: "New Clients",
    progress: "0.40",
    increase: "+20%",
    icon: PersonAddIcon,
    day: 2,
  },
  {
    title: "1,500,134",
    subtitle: "Traffic Received",
    progress: "0.70",
    increase: "+35%",
    icon: TrafficIcon,
    day: 2,
  },
  {
    title: "11,361",
    subtitle: "Emails Sent",
    progress: "0.65",
    increase: "+24%",
    icon: EmailIcon,
    day: 3,
  },
  {
    title: "401,225",
    subtitle: "Sales Obtained",
    progress: "0.450",
    increase: "+20%",
    icon: PointOfSaleIcon,
    day: 3,
  },
  {
    title: "30,441",
    subtitle: "New Clients",
    progress: "0.30",
    increase: "+5%",
    icon: PersonAddIcon,
    day: 3,
  },
  {
    title: "1,425,134",
    subtitle: "Traffic Received",
    progress: "0.75",
    increase: "+50%",
    icon: TrafficIcon,
    day: 3,
  },
];

export const dashboardData1 = [
  {
    title: { ru: "Остаток на начало", uz: "Boshlang'ich balans" },
    price: 110152287,
  },
  {
    title: { ru: "Приход", uz: "Kirim" },
    price: 12389305,
  },
  {
    title: { ru: "Фактический расход", uz: "Chiqim" },
    price: 7591544,
  },
  {
    title: { ru: "Фактический остаток", uz: "Qoldiq" },
    price: 110152287 + 12389305 - 7591544,
  },
  {
    title: { ru: "Объем реализации (выручка)", uz: "Sotish hajmi (daromad)" },
    price: 12753000,
  },
  {
    title: { ru: "Фактическая наценка", uz: "Haqiqiy foyda" },
    price: 12753000 - 7591544,
  },
  {
    title: { ru: "Факт нацен %", uz: "Haqiqiy foyda %" },
    price: ((12753000 - 7591544) / 7591544) * 100,
  },
];

export const departmentData = [
  {
    id: 1,
    department_name: { ru: "Чайная комната", uz: "Choyxona" },
    balance: 110152287,
    receipt: 12389305,
    shipment: 7591544,
    actual_balance: 110152287 + 12389305 - 7591544,
    sales: 12753000,
    actual_markup: 12753000 - 7591544,
    percent: ((12753000 - 7591544) / 7591544) * 100,
  },
  {
    id: 2,
    department_name: { ru: "Национальные блюда", uz: "Milliy taomlar" },
    balance: 110152287,
    receipt: 12389305,
    shipment: 7591544,
    actual_balance: 110152287 + 12389305 - 7591544,
    sales: 12753000,
    actual_markup: 12753000 - 7591544,
    percent: ((12753000 - 7591544) / 7591544) * 100,
  },
  {
    id: 3,
    department_name: { ru: "Быстрое питание", uz: "Fastfood" },
    balance: 110152287,
    receipt: 12389305,
    shipment: 7591544,
    actual_balance: 110152287 + 12389305 - 7591544,
    sales: 12753000,
    actual_markup: 12753000 - 7591544,
    percent: ((12753000 - 7591544) / 7591544) * 100,
  },
  {
    id: 4,
    department_name: { ru: "Склад", uz: "Omborxona" },
    balance: 110152287,
    receipt: 12389305,
    shipment: 7591544,
    actual_balance: 110152287 + 12389305 - 7591544,
    sales: 12753000,
    actual_markup: 12753000 - 7591544,
    percent: ((12753000 - 7591544) / 7591544) * 100,
  },
  {
    id: 5,
    department_name: { ru: "Итог", uz: "Natija" },
    balance: 110152287,
    receipt: 12389305,
    shipment: 7591544,
    actual_balance: 110152287 + 12389305 - 7591544,
    sales: 12753000,
    actual_markup: 12753000 - 7591544,
    percent: ((12753000 - 7591544) / 7591544) * 100,
  },
];

export const productData = [
  {
    tr: 1,
    id: 1,
    code: "001",
    name: "Olma",
    uom: "kg",
    price: 20000,
    quantity: 100,
    date: "18.06.2024",
  },
  {
    tr: 2,
    id: 2,
    code: "002",
    name: "Anor",
    uom: "kg",
    price: 30000,
    quantity: 100,
    date: "18.06.2024",
  },
  {
    tr: 3,
    id: 3,
    code: "003",
    name: "Olma(qizil)",
    uom: "kg",
    price: 25000,
    quantity: 100,
    date: "18.06.2024",
  },
  {
    tr: 4,
    id: 4,
    code: "004",
    name: "Shaftoli(eroni)",
    uom: "kg",
    price: 80000,
    quantity: 100,
    date: "18.06.2024",
  },
  {
    tr: 5,
    id: 5,
    code: "005",
    name: "O'rik",
    uom: "kg",
    price: 20000,
    quantity: 100,
    date: "18.06.2024",
  },
];

export const uom = [
  {
    name: "kg",
    value: "kg",
  },
  {
    name: "pc",
    value: "pc",
  },
  {
    name: "metr",
    value: "metr",
  },
  {
    name: "litr",
    value: "litr",
  },
];

export const sale = [
  {
    id: 1,
    tr: 1,
    price: 20000000,
    date: "18.06.2024",
  },
  {
    id: 2,
    tr: 2,
    price: 20000000,
    date: "18.06.2024",
  },
  {
    id: 3,
    tr: 3,
    price: 20000000,
    date: "18.06.2024",
  },
  {
    id: 4,
    tr: 4,
    price: 20000000,
    date: "18.06.2024",
  },
];
